<template>
    <!-- 导航 -->
    <page-head title="比赛日训练"/>
    <content-bg>
        <template v-slot:content>
            <data-report-structure>
                <template v-slot:left v-if="pageType === 'player'">
                    <select-style :list="userTeamList" label="name" key="id" v-model:value="teamId"
                                  @changeFn="changeFn"></select-style>
                </template>
                <template v-slot:center>{{ playerName }}</template>
                <template v-slot:right>{{ $moment().format('YYYY') }} 01/01 - {{ $moment().format('MM/DD') }}</template>
                <template v-slot:content>
                    <div class="barBox" v-for="(item, index) in filterArr" v-if="filterArr.length && dateArr.length">
                        <div class="title">
                            <p>{{ item.displayName }}</p>
                            <ul class="label">
                                <li v-for="item in colors">
                                    <i :style="{background: item.color}"></i>
                                    <span>{{ item.value }}</span>
                                </li>
                            </ul>
                        </div>
                        <div :key="index" class="bar-item" :id="'bar-item'+index"></div>
                    </div>
                    <no-data v-else></no-data>
                </template>
            </data-report-structure>
        </template>
    </content-bg>
</template>

<script>
import {useRoute} from "vue-router";
import {getCurrentInstance, reactive, toRefs} from "vue";
import {useStore} from "vuex";

export default {
    name: "details",
    setup() {
        const route = useRoute()
        const {proxy} = getCurrentInstance()
        const store = useStore();

        const state = reactive({
            playerName: route.query.name,
            pageType: route.query.pageType,
            colors: [
                {color: "#FFC700", value: "M-4", name: '（M-4比赛日前4天）'},
                {color: "#00D190", value: "M-3", name: '（M-3比赛日前3天）'},
                {color: "#3663E1", value: "M-2", name: '（M-2比赛日前2天）'},
                {color: "#FF5630", value: "M-1", name: '（M-1比赛日前1天）'},
                {color: "#7E45FF", value: "M", name: 'M'},
            ],
            filterArr: [],

            matchData: [],
            dateArr: [],
            dataLength: 8,

            userTeamList: [],
            teamId: {}
        });

        //  获取gps 筛选项
        const getFilter = () => {
            const {id} = store.state.teamData;
            let data = {
                teamId: id
            }
            proxy.$server.getGpsFilter(data).then(res => {
                if (res.code === 200) {
                    if (res.data && res.data.length) {
                        let arr = [];
                        res.data.map(item => {
                            if (item.gps) {
                                arr.push(item)
                            }

                        })
                        state.filterArr = arr;
                        getData()
                    }
                }
            })
        }

        const getData = () => {
            const {id} = store.state.teamData;
            const data = {
                playerId: route.query.id,
                // playerId: '6100f3c24b2c714800ef6f8d',
                teamId: id,
                startDate: proxy.$Config.currentYearFirstDay,
                endDate: proxy.$moment().format('YYYY/MM/DD')
            }
            proxy.$server.getMatch_dateReport(data).then(res => {
                res.data = Array.isArray(res.data) ? {} : res.data;

                let matchData = Object.values(res.data);
                let dateArr = Object.keys(res.data)

                state.matchData = matchData.slice(0, state.dataLength)
                state.dateArr = dateArr.slice(0, state.dataLength)

                console.log(state.dateArr)
                proxy.$nextTick(() => {
                    renderEcharts()
                })
            })
        }

        const renderEcharts = async () => {
            if (!state.matchData.length) {
                return
            }

            const arr = [...state.colors]
            arr.reverse();

            state.filterArr.forEach((item, index) => {
                let dom = document.getElementById('bar-item' + index);

                if ((state.matchData.length * 100) > dom.offsetWidth) {
                    dom.style.width = (state.matchData.length * 200) + 'px'
                }

                let myChart = proxy.$echarts.init(dom);
                let option = null;

                let series = [];
                let barData = [];

                const matchData = [];

                for (let i = 0; i < arr.length; i++) {
                    let emptyArr = new Array(state.filterArr.length).fill(0)
                    matchData.push(emptyArr)
                }
                state.matchData.forEach((match, i1) => {
                    match.forEach((matchDay, i2) => {
                        let obj = matchDay.data.find(slugItem => slugItem.slug === item.slug)
                        if (obj) {
                            matchData[i2][i1] = Number(obj.value || 0).toFixed(item.decimal || 2)
                        }
                    })
                })

                arr.forEach((colorItem, colorIndex) => {
                    barData.push({
                        ...colorItem,
                        data: matchData[colorIndex]
                    })
                })

                // console.log(barData)

                myChart.resize();

                //遍历所得到的数据，分别创建不同的bar->series
                barData.forEach((itemChild) => {
                    series.push(
                        {
                            type: 'bar',
                            name: item['name'],
                            barGap: .5,
                            itemStyle: {
                                normal: {
                                    borderRadius: [10],
                                    color: (params) => {
                                        return params.data.color
                                    }
                                }
                            },
                            data: itemChild.data.map(item => {
                                return {
                                    value: Number(item) > 0 ? item : '',
                                    name: itemChild.name,
                                    key: itemChild.value,
                                    color: itemChild.color,
                                }
                            }),
                            z: 100,
                            barWidth: 16,
                            label: {
                                show: true,
                                rotate: '90',
                                position: 'top',
                                align: 'left',
                                verticalAlign: 'middle',
                                distance: '5',
                                color: itemChild.color,
                                fontSize: 18,
                                fontFamily: 'akrobatBold',
                                formatter: function (params) {
                                    return [
                                        params.value,
                                        '{matchKey|' + params.data.key + '}',
                                    ].join('  ');
                                },
                                rich: {
                                    matchKey: {
                                        color: '#787885',
                                        fontSize: 18,
                                        fontFamily: 'akrobatRegular',
                                    },
                                },
                            },
                        },
                    )
                })


                option = {
                    grid: {
                        top: '80',
                        left: '50',
                        right: '50',
                        bottom: '40',
                        containLabel: true,
                    },
                    xAxis: [
                        {
                            show: true,
                            type: "category",
                            data: state.dateArr,
                            axisLine: {
                                show: false,  // 是否显示
                            },
                            axisLabel: {
                                //坐标轴刻度标签的相关设置。
                                show: true,
                                textStyle: {
                                    color: 'rgba(255, 255, 255, .6)',
                                    fontSize: 26,
                                    fontFamily: 'akrobatRegular',
                                },
                                margin: 15,
                            },
                            axisTick: {
                                show: false
                            },
                            splitLine: {
                                show: false
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            show: true,
                            min: 0,
                            splitLine: {
                                lineStyle: {
                                    color: 'rgba(255, 255, 255, .3)',
                                    type: 'dotted'
                                },
                                show: true
                            },
                            axisLabel: {
                                //坐标轴刻度标签的相关设置。
                                show: true,
                                textStyle: {
                                    color: 'rgba(255, 255, 255, .6)',
                                    fontSize: 26,
                                    fontFamily: 'akrobatRegular',
                                },
                            },
                        }
                    ],
                    series: series
                };

                if (option && typeof option === "object") {
                    myChart.setOption(option, true);
                }

            })
        }

        if (state.pageType === 'player') {
            const params = {
                userId: route.query.id,
                userType: 'player'
            }
            proxy.$server.getUserTeams(params)
                .then(res => {
                    console.log(res);
                    state.userTeamList = res.data;
                    changeFn(state.userTeamList[0])
                })
        } else {
            getFilter();
        }

        const changeFn = (item) => {
            state.teamId = item;
            store.commit('setTeamData', item)
            getFilter();
        }

        return {
            ...toRefs(state),

            changeFn
        }
    }
}
</script>

<style scoped lang="scss">
.barBox {
    height: 680px;
    width: 100%;

    .title {
        position: relative;

        p {
            padding-top: 24px;
            padding-bottom: 18px;
            font-size: 22px;
            line-height: 31px;
            color: rgba(255, 255, 255, .8);
            font-family: PingFangMedium;
            padding-left: 50px;
        }

        .label {
            position: absolute;
            right: 50px;
            top: 26px;
            font-size: 22px;
            font-family: akrobatRegular;
            color: rgba(255, 255, 255, .8);

            li {
                float: left;
                margin-right: 14px;

                i {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    margin-right: 6px;
                    border-radius: 6px;
                }

                span {
                    font-family: akrobatRegular;
                }
            }
        }
    }

    .bar-item {
        height: calc(100% - 80px);
        width: 100%;
    }
}
</style>